import React from "react"

// imported components
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Richcard, RichcardLink} from "../components/card"
import Intro from "../components/intro"

// imported styles
import mainStyles from "../styles/css/main.module.scss"
import introStyles from "../styles/css/intro.module.scss"

// images
const hammer = require("../img/emojis/emoji_hammer_wrench.png")
const memo = require("../img/emojis/emoji_memo.png")
const books = require("../img/emojis/emoji_books.png")
const trophy = require("../img/emojis/emoji_trophy.png")
const box = require("../img/emojis/emoji_package.png")

const IndexPage = () => (
  <Layout>
    <SEO 
      title = "Product Manager Guides"
    />
    
    {/* Intro section */}
    <section className={introStyles.introductionLogo}>
        <div className={[introStyles.introduction_container, mainStyles.mainPadding].join(' ')}>

          <Intro
            introHeading = "Product Manager Guides"
            introBody = "Resources for the independent doer to break into and advance in product management"
          />

        </div>
    </section>

    <section className={mainStyles.majorSection} id="guides">
      <div className={mainStyles.mainPadding}>
        
        <div className={[mainStyles.mainExplanatoryContent_wide, mainStyles.paddingBottom3].join(' ')}>
          <h2>In-depth product manager guides</h2>
          <p>Resources to jump start your journey into product management</p>
        </div>

        <RichcardLink
          cardName = "Playbook"
          cardTitle = "Job Guide"
          cardBody = "Our highly rated no-nonsense guide to becoming a product manager"
          cardLink = "/guide"
          cardBackground = {mainStyles.pmstarterpack}
          cardEmoji = {box}
          cardAlt = "PM Resources"
        />

        <RichcardLink
          cardName = "Tutorial"
          cardTitle = "Complete a product case"
          cardBody = "Learn how to create requirements and set strategy"
          cardLink = "/case"
          cardBackground = {mainStyles.fieldership}
          cardEmoji = {trophy}
          cardAlt = "Progressions"
        />
        
        <Richcard
          cardName = "Tutorial"
          cardTitle = "How to build a product"
          cardBody = "Bootstrap your product experience by designing and building a no-code product"
          cardLink = "https://www.lumenful.com/read/torch-build"
          cardBackground = {mainStyles.uxstarterpack}
          cardEmoji = {hammer}
          cardAlt = "Progressions"
        />

        <RichcardLink
          cardName = "Playbook"
          cardTitle = "Writing capital playbook"
          cardBody = "How to write to build professional capital as a product expert"
          cardLink = "/write"
          cardBackground = {mainStyles.workreef}
          cardEmoji = {memo}
          cardAlt = "Capital"
        />

        <Richcard
          cardName = "Book"
          cardTitle = "Product Knacks"
          cardBody = "Frameworks to make you a highly effective product manager"
          cardLink = "https://www.lumenful.com/read/product-knacks"
          cardBackground = {mainStyles.courses}
          cardEmoji = {books}
          cardAlt = "PM Resources"
        />

      </div>
    </section>  
    
  </Layout>
)

export default IndexPage
