import React from "react"
import { Link } from "gatsby"
import mainStyles from "../styles/css/main.module.scss"
import introStyles from "../styles/css/intro.module.scss"

/*
available props
-
introHeading
introBody

*/

const Intro = props => (
    <article className={introStyles.hero_card}>
        <h1>{props.introHeading}</h1>
        <p className={mainStyles.paddingBottom4}>{props.introBody}</p>
        <div className={[introStyles.btn_container, mainStyles.paddingBottom2].join(' ')}>

            <Link className={[mainStyles.btn_large, introStyles.btn_secondary, mainStyles.btn_secondary].join(' ')} to="/guide">
                Job Guide
            </Link>

            <Link className={[mainStyles.btn_large, introStyles.btn_tertiary_teal, mainStyles.btn_tertiary_teal].join(' ')} to="#guides">
                More Guides
            </Link>

        </div>
    </article>
)

export default Intro
